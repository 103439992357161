<template>
  <div class="guide-container">
    <h1>What is cryptocurrency and how does it work</h1>

    <img
      src="@/assets/imgs/Articles/bitcoin-in-computer-motherboard.jpg"
      alt="a glowing bitcoin sat on a motherboard"
    />
    <br />
    <h2>Digital currency</h2>
    <p>
      Cryptocurrency is a digital or virtual currency that uses cryptography for
      security and is decentralized, meaning it is not controlled by any single
      entity or government. Cryptocurrencies are built on a technology called
      blockchain, which is a distributed ledger that records transactions across
      a network of computers.
    </p>
    <p>
      One of the most well-known cryptocurrencies is Bitcoin, which was created
      in 2009. Since then, hundreds of different cryptocurrencies have been
      developed. Some of the most popular ones include Ethereum, Litecoin, and
      XRP.
    </p>
    <p>
      Cryptocurrencies can be used as a medium of exchange, just like
      traditional currencies like the US dollar or the euro. However, they have
      some unique features that set them apart.
    </p>

    <!-- <img
      src="@/assets/imgs/Articles/WhatIsCryptoCurrency/connected-computers-cyberpunk-style.jpg"
      alt="Many computers connected together dipicting the blockchain"
    /> -->
    <br />
    <h2>Decentralized</h2>
    <p>
      One of the main advantages of cryptocurrencies is that they are
      decentralized and secure. Transactions are recorded on a decentralized,
      public ledger called the blockchain, which is managed by a network of
      computers rather than a central authority. This means that there is no
      central point of control, making it difficult for governments or financial
      institutions to manipulate or censor transactions.
    </p>
    <p>
      Cryptocurrencies are also highly secure because they use cryptography to
      protect against fraud and hacking. Transactions are verified by network
      nodes through cryptography and recorded in a public, decentralized ledger
      called the blockchain.
    </p>
    <br />
    <h2>Faster and cheaper</h2>
    <p>
      Another advantage of cryptocurrencies is that they are fast and cheap to
      send. Transactions are processed almost instantly and are generally
      cheaper than traditional bank transfers, which can take days to process
      and can be expensive due to fees.
    </p>

    <!-- <img
      src="@/assets/imgs/Articles/WhatIsCryptoCurrency/stock-market-chart-showing-volatility.jpg"
      alt="A stock market chart on a computer showing the volatility of cryptocurrencies"
    /> -->

    <br />
    <h2>Volatile</h2>
    <p>
      There are also a few potential downsides to cryptocurrencies. One is that
      their value can be volatile, meaning that it can fluctuate significantly
      in a short period of time. This can make them risky to invest in,
      especially for those who are not familiar with the market. Additionally,
      cryptocurrencies are not yet widely accepted as a form of payment, so it
      may be difficult to use them in everyday transactions.
    </p>
    <p>
      In summary, cryptocurrency is a digital or virtual currency that uses
      cryptography for security and is decentralized. It is built on the
      technology called blockchain and can be used as a medium of exchange just
      like traditional currencies. Cryptocurrencies have some unique features
      that set them apart, including being secure, decentralized, and fast and
      cheap to send. However, they can also be volatile and not widely accepted
      as a form of payment.
    </p>
  </div>
</template>

<script>
export default {};
</script>
